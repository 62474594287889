import React from "react";
import {Box, Container, Fade, Stack, Typography} from "@mui/material";
import ResponsiveImage from "../Components/ResponsiveImage";
import RedirectComponent from "./Redirect.tsx";
import Footer from "./Footer.tsx";

const Home = () => {
    
    return <>
        <Fade in={true} timeout={1000}>
            <Box sx={{display: 'block'}}>
                <ResponsiveImage src="/images/lkwwalter_login_background.png" sx={{height: "400px"}}/>
            </Box>
        </Fade>
        <Container>
            <Box sx={{minHeight: '100vh', position: 'relative', paddingBottom: '36px'}}>
                    <Stack id='headline' sx={{mt: 4}}>
                        <Typography variant='h4'>
                            FLEET by LKW WALTER ist umgezogen!
                        </Typography>

                        <Stack id='greating' direction='row'>
                            <Stack>
                                <Typography variant="body1" sx={{fontSize: "1.2rem"}} textAlign="justify">
                                    Fürs Erste werden Sie weiterhin automatisch auf die neue URL weitergeleitet. Künftig
                                    rufen Sie
                                    bitte direkt die neue Website unter folgendem Link auf:<br/> <a
                                    href={"https://fleet.lkw-walter.com"}>https://fleet.lkw-walter.com</a>.
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack id='headline' sx={{mt: 8}}>
                            <Typography variant='h4'>
                                FFLEET by LKW WALTER has moved!
                            </Typography>
                        </Stack>
                        <Stack id='greating' direction='row'>
                            <Stack>
                                <Typography variant='body1' sx={{fontSize: '1.2rem'}} textAlign='justify'>
                                    For now, you'll be automatically redirected to the new URL. In the future, please go
                                    directly to the new website at:<br/> <a
                                    href={"https://fleet.lkw-walter.com"}>https://fleet.lkw-walter.com</a>.
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{mt: 8}}><RedirectComponent/></Stack>
                    </Stack>
                <Footer/>
            </Box>
        </Container>
    </>
}

export default Home